.mission-section {
    padding-bottom: 1rem;
}

.mission-header {
    font-size: 1.2rem;
    border-radius: 25%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0rem auto .5rem auto;
    padding-top: 4rem;
    color: grey;
}

.underline {
    height: 1px;
    width: 30px;
    margin: 0 auto 1.5rem auto;
    background-color: grey;
    z-index: 10;
    opacity: .5;
}

.black {
    background-color: black;
    opacity: 1;
    margin-top: -5px;
}

.mission-statement {
    font-size: 2rem;
    font-family: "Cormorant Garamond", serif;
    font-weight: 400;
    font-style: normal;
}

.products-section {
    text-align: left;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
    -webkit-justify-content: space-evenly;
       -moz-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 75px;
    background: rgb(233,233,233);
    background: -webkit-linear-gradient(300deg, rgba(68,68,68,.7) 0%, rgba(233,233,233,1) 15%, rgba(199,199,199,.5) 74%, rgba(68,68,68,1) 100%);
    background: -moz-linear-gradient(300deg, rgba(68,68,68,.7) 0%, rgba(233,233,233,1) 15%, rgba(199,199,199,.5) 74%, rgba(68,68,68,1) 100%);
    background: -o-linear-gradient(300deg, rgba(68,68,68,.7) 0%, rgba(233,233,233,1) 15%, rgba(199,199,199,.5) 74%, rgba(68,68,68,1) 100%);
    background: linear-gradient(150deg, rgba(68,68,68,.7) 0%, rgba(233,233,233,1) 15%, rgba(199,199,199,.5) 74%, rgba(68,68,68,1) 100%);  
    padding: 75px 0;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}

.photos {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: space-evenly;
    -webkit-justify-content: space-evenly;
       -moz-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}

.products-section img {
    width: 530px;
    opacity: .85;
}

.products-section .text {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: start;
       -moz-box-align: start;
        -ms-flex-align: start;
            align-items: start;
    width: 80%;
    margin-bottom: 75px;
}

.text h3 { 
    margin: 0 auto;
    text-align: center;
    margin-bottom: 1rem;
}

.customers-section {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
    -webkit-justify-content: space-evenly;
       -moz-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 75px;
    text-align: left;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}

.customers-section img {
    width: 600px;
    opacity: .9;
}

.customers-section .text {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 40%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;

}

.grey-background {
    background: rgb(233,233,233);
    background: -webkit-linear-gradient(30deg, rgba(68,68,68,.7) 0%, rgba(233,233,233,1) 26%, rgba(199,199,199,.5) 85%, rgba(68,68,68,1) 100%);
    background: -moz-linear-gradient(30deg, rgba(68,68,68,.7) 0%, rgba(233,233,233,1) 26%, rgba(199,199,199,.5) 85%, rgba(68,68,68,1) 100%);
    background: -o-linear-gradient(30deg, rgba(68,68,68,.7) 0%, rgba(233,233,233,1) 26%, rgba(199,199,199,.5) 85%, rgba(68,68,68,1) 100%);
    background: linear-gradient(60deg, rgba(68,68,68,.7) 0%, rgba(233,233,233,1) 26%, rgba(199,199,199,.5) 85%, rgba(68,68,68,1) 100%);  }

.about-section {
    margin-top: 75px;
    padding: 75px 0;
}

.about-header {
    margin-top: 0px;
}

.about-section img {
    width: 350px;
}

.bio {
    width: 80%;
    margin: 0 auto;
    text-align: left;
}

.future-section {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: 150px;
    height: 500px;
    background-image: url(../../assets/rut-miit-YIdkWynJdSk-unsplash.jpg);
    background-position: bottom -160px right 0px;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    margin: 0 20px;
}

.future-section img {
    display: none;
}

.future-section h3, .future-section p {
    color: white;
    text-shadow: 1px 1px black;
}

.future-section .underline {
    background-color: white;
}

footer {
    display: inline-block;
    width: 100%;
    height: 110px;
}

.footer-container {
    background-color: black;
    color: white;
    opacity: .8;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
    -webkit-justify-content: space-evenly;
       -moz-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 1.3rem;
    margin: 0 20px;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    height: 100px;
}

footer span {
    text-wrap: balance;
}

.mobile-footer-numbers, .mobile-footer-numbers-title, .mobile-footer-numbers-container {
    display: none;
}


@media only screen and (max-width: 994px) {

    .products-section {
        padding-bottom: 50px;
    }

    .products-section .text {
        width: 80%;
        margin-bottom: 0px;
    }

    .products-section img {
        margin: 20px 0;
        width: 95%;
    }

    .customers-section h3 {
        margin-top: 0px;
    }

    .customers-section img {
        width: 95%;
    }

    .customers-section .text {
        width: 80%;
        margin: 0px 0 25px 0;
    }

    .bio {
        width: 80%;
    }

    .future-section {
        height: 300px;
    }

    .future-section img {
        display: hidden;
    }

    footer {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content; 
    }

    .footer-container {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding: 15px 0px;
    }

    footer {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content; 
    }

    footer span {
        margin: 5px 0px; 
    }
}

@media only screen and (max-width: 430px) {
    .mission-section {
        padding: 0 5%;
        text-align: left;
    }

    .mission-statement {
        padding: 0 0 0 2rem;
    }

    .about-section {
        padding-bottom: 0;
    }

    .about-section img {
        width: 275px;
    }

    .future-section {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        overflow: hidden;
        background-image: none; 
        -webkit-box-align: last baseline; 
        -webkit-align-items: last baseline; 
           -moz-box-align: last baseline; 
            -ms-flex-align: last baseline; 
                align-items: last baseline;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }
    .future-section img{
        width: 430px;
        display: block;
        position: relative;
        left: 4%;
        -webkit-clip-path: inset(37% 0 0 0);
                clip-path: inset(37% 0 0 0);
    }

    .footer-container {
        height: 185px;
    }

    .not-for-mobile {
        display: none;
    }

    .mobile-footer-numbers-container {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
           -moz-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
           -moz-box-align: center;
            -ms-flex-align: center;
                align-items: center;
    }

    .mobile-footer-numbers-title {
        display: block;
    }

    .mobile-footer-numbers {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: end;
        -webkit-align-items: end;
           -moz-box-align: end;
            -ms-flex-align: end;
                align-items: end;  
    }

    .mobile-footer-numbers span {
        margin-left: 10px;
        margin: 0 0 0 10px;
    }
}



