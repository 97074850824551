.App {
  text-align: center;
  margin: 0px;
  overflow: hidden;
  padding: 0 0px;
  background-color: white;
}

h2, h3 {
  font-family: "Ovo", serif;;
}

p {
  font-size: 1.3rem;
  line-height: 2rem;
  color: grey;
  font-family: "Quattrocento Sans", sans-serif;
  text-indent: 2rem;
}



