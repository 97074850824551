.header h1 {
    padding: 40px 0;
    margin: 0;
    font-family: "Cinzel Decorative", serif;
    font-weight: 400;
    font-style: normal;
    color: black;
    text-shadow: 0px 1px black;
    font-size: 2.5rem;
    letter-spacing: 5px;
    z-index: 5;
}