.banner{
    height: 50rem;
    background-image: url(../../assets/kenny-eliason-zFSo6bnZJTw-unsplash\(1\).jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0 20px;
}

.banner img {
    display: none;
    position: absolute;
    z-index: 0;
    height: 30rem;
}

.banner span {
    font-size: 6rem;
    color: white;
    margin-bottom: 9rem;
    font-family: "Cormorant Garamond", serif;
    font-weight: 600;
    font-style: italic;
    text-shadow: 1px 2px black;
    z-index: 5;
    width: 100%;
}

@media only screen and (max-width: 430px) {
    .banner {
        height: 30rem;
        background-image: none;
        width: 100%;
        overflow: hidden;
        margin: 0;
        -webkit-transform: scale(1);
           -moz-transform: scale(1);
            -ms-transform: scale(1);
             -o-transform: scale(1);
                transform: scale(1);
    }

    .banner span {
        font-size: 2.5rem;
        margin-bottom: 0;
    }

    .banner img {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
    }
}
